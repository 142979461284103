<template>
<main class="share" data-color="#1c1c1c">
  <span class="share__line" ref="line" />

  <ul class="share__list" ref="bigTitle">
    <li
      v-for="share in shareList"
      :key="share.name"
    >
      <a
        :href="share.href"
        target="_blank"
        rel="noopener"
        :aria-label="share.label"
        @click="$sendEvent('Share')"
      >
        <p class="u-split-container">
          <span class="split-line">
            {{ share.name }}
          </span>
        </p>

        <span class="share__hand">
          <span>SHARE</span>
          <img class="hand__image" src="/assets/svg/handshake-gold.svg" alt="handshake">
          <span>NOW</span>
        </span>
      </a>
    </li>
  </ul>

  <div class="share__content">
    <AppSlogan class="share__title" ref="titleSplit" variant />

    <p ref="content">
      If you are not an employer, but you know someone who could be interested, invite her or him to be part of the Hiring Chain revolution.
    </p>
  </div>
</main>
</template>

<script>
import { gsap } from 'gsap';
import AppSlogan from '@/components/AppSlogan.vue';
import { init, enter } from '@/scripts/animations';
import { isTouchDevice } from '@/scripts/utils';

export default {
  name: 'Share',

  components: {
    AppSlogan,
  },

  data() {
    return {
      shareList: [
        {
          name: 'Linkedin',
          href: 'https://www.linkedin.com/sharing/share-offsite/?url=https://www.hiringchain.org/',
          label: 'Share on Linkedin',
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/intent/tweet?text=People%20with%20Down%20syndrome%20want%20to%20work%20for%20the%20same%20reasons%20as%20anyone%20else.%20But%20they%20often%20face%20barriers,%20prejudice%20and%20lack%20of%20opportunities.%20You%20can%20help%20make%20a%20change.%20Start%20the%20Hiring%20Chain%20today&url=https://www.hiringchain.org/&hashtags=HiringChain',
          label: 'Share on Twitter',
        },
        {
          name: 'Facebook',
          href: 'https://www.facebook.com/sharer/sharer.php?u=https://www.hiringchain.org/',
          label: 'Share on Facebook',
        },
        {
          name: 'Email',
          href: 'mailto:?subject=Start the Hiring Chain today&amp;body=People with Down syndrome want to work for the same reasons as anyone else. But they often face barriers, prejudice and lack of opportunities. You can help make a change. https://www.hiringchain.org/',
          label: 'Share by Email',
        },
      ],
    };
  },

  methods: {
    enter() {
      const hands = [...this.$el.querySelectorAll('.share__hand')];
      init.get('bigTitle')(this.$refs.bigTitle);
      init.get('bigTitle')(this.$refs.titleSplit.$el);
      init.get('fade')(this.$refs.content, { y: 60 });
      if (isTouchDevice()) init.get('fade')(hands, { y: 30 });
      gsap.set(this.$refs.line, { scaleY: 0, transformOrigin: '50% 0' });

      const tl = gsap.timeline();

      tl.addLabel('start')
        .to(this.$refs.line, {
          scaleY: 1,
          duration: 3.5,
          ease: 'coor',
          clearProps: 'all',
        }, 'start')
        .add(enter.get('bigTitle')(this.$refs.bigTitle), 'start')
        .add(enter.get('bigTitle')(this.$refs.titleSplit.$el), 'start')
        .add(enter.get('fade')(this.$refs.content), 'start+=0.8');

      if (isTouchDevice()) tl.add(enter.get('fade')(hands, 0.25), 'start+=0.2');

      return tl;
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  width: 100vw;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100.1);
  padding: 16.25vh 6.4vw 7.4vh;
  padding: calc(var(--vh, 1vh) * 16.25) 6.4vw calc(var(--vh, 1vh) * 2.4);

  color: $white;

  @extend %grid-column;

  &__line {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 65%;

    background-color: $white;

    transform: translateX(-50%) translateY(-50%);
  }

  @include mq($until: ipadP) {
    &__line {
      display: none;
    }
    &__title {
      font-weight: normal;
      font-family: 'Tropiline';
      font-size: rem(20px);
      font-size: calc(20px * var(--rw));
      line-height: 0.85;
      letter-spacing: 0;
      flex: 0 0 50%;
    }
  }

  @include mq(ipadP) {
    padding: 2vh 4vw 0;

    &__line {
      transform: translateX(-50%) translateY(calc(-50% + 1vh));
    }
  }

  .modal__close-button {
    position: absolute;
    top: 4vw;
    left: 4vw;
  }

  &__list {
    grid-column: 1 / -1;
    padding-bottom: 10vh;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: column nowrap;

    @include mq(ipadP) {
      padding-bottom: 0;

      display: flex;
      align-items: center;
      flex-flow: column nowrap;
    }

    li {
      position: relative;

      p {
        @extend %font-share-list;
      }

      &:not(:last-child) {
        margin-bottom: 6.15vh;
        margin-bottom: calc(var(--vh, 1vh) * 6.15);
      }
    }

    .share__hand {
      position: absolute;
      bottom: -20px;
      left: 50%;
      width: max-content;

      transform: translate3d(-50%, 0, 0);

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;

      @include mq($until: ipadP) {
        width: 100vw;
      }

      span {
        transition: transform 0.5s $ease-out;
        line-height: 0.8;

        @include mq($until: ipadP) {
          width: fit-content;
          flex: 0 0 calc(50% - 30px);

          &:nth-child(odd):not(:first-child) {
            text-align: left;
          }
          &:first-child {
            text-align: right;
          }
        }
      }

      img {
        flex: 0 0 25px;
        width: 25px;
        margin: 0 15px;
        transition: transform 0.5s $ease-out;
      }
    }

    @include mq(ipadP) {
      grid-column: 2 / 9;
      align-self: center;
      justify-self: center;
    }

    @include mq($and: $is-not-touch-device) {
      a {
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          width: 5vw;
          height: 1px;

          background-color: $gold;
          transform: translate3d(0, -50%, 0) scaleX(0);
          transition: transform 0.5s $ease-out;
        }

        &::before {
          left: calc(-5vw - 20px);
          transform-origin: 100% 50%;
        }
        &::after {
          right: calc(-5vw - 20px);
          transform-origin: 0 50%;
        }
      }

      .share__hand span,
      .share__hand img {
        transform: translate3d(0, -150%, 0);
      }

      a:hover::before,
      a:hover::after {
        transform: translate3d(0, -50%, 0) scaleX(1);
      }
      a:hover .share__hand span,
      a:hover .share__hand img {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__content {
    display: none;

    @include mq(ipadP) {
      grid-column: 10 / -2;
      align-self: center;
      justify-self: center;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;

      p {
        margin-top: 8.4vh;
        margin-top: calc(var(--vh, 1vh) * 8.4);
      }
    }

    @include mq(ipadL) {
      p {
        width: 75%;
      }
    }
  }
}
</style>
